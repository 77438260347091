import React,{useEffect,useState} from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';


function OrderDetails() {

  const [isloading,setisloading] =useState(true)

    const [orderDetail,setorderDetail] =useState([])

    const {id}= useParams("id")

    const getOrderById = async () => {

        try {
          const response = await axios.post(`${process.env.REACT_APP_API_ORDERS_BY_ID}`,{"orderId":id});
          setorderDetail(response.data.data[0])
          
          setisloading(false)
        } catch (error) {
          console.log(error)
        }
      }
      useEffect(() => {
        getOrderById()
    
      }, []);

      
      console.log(orderDetail)
  return (
    <div className='container mt-3'>
     <h4>OrderDetails</h4>

      {isloading ? (<>...Loading</>) :
      
      
     (

      <>

      <div className="row"> 
        <div className="col-lg-6">
        <UserInfo data={orderDetail.userDetails}/>

        <div className="card mt-2">

          <div className="card-body">
            <p>Date Of Order: {orderDetail.DOO} </p>
            <p>Order Status: {orderDetail.pending == "Y" ? "Pending" :"Dispatched"}</p>


          </div>

        </div>
        </div>
        <div className="col-lg-6">
        <DisplayCard data={orderDetail.cartItems}/> 
             <div className="card mt-2">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-center">
                <p>
                Total : &#8377; {orderDetail.totalAmount} INR
                  </p> 
              </div>
            </div>
          </div>
        </div>
      </div>
    
          
      </>
     ) 
      
      }

      
    

      
        
    </div>
  )
}

export default OrderDetails



function DisplayCard({data}){


  var disdata = JSON.parse(data)


  return(

    <>
    <div className="card mt-2">
        <div className="card-header">
        <div className="card-title">
          Cart Items
        </div>

       
        </div>
       
        <div className="card-body">
          {disdata.map((x,i)=>{


            return (
              <>
              <div key={x.productId} className="card p-2 mb-2">

                
                <div className="d-flex align-items-center justify-content-start">
                  <p className='mb-0 m-2 w-40'>
                {x.productTitle}</p>
                <p className='mb-0 m-2'>Quantity : {x.qty} X &#8377;{x.productPrice} INR</p>                  
                </div>

              </div>
              </>
            )
          })}

         
        </div>
      </div>
    </>
  )
}

function UserInfo({data}){

  console.log(data)
  var disdata = JSON.parse(data)

  return(
    <>
    <div className="card mt-2">
            <div className="card-body">
              
            <table class="table table-bordered">
  
  <tbody>
  
    <tr>
    <td>Name:</td>
    <td colspan="2">{disdata.firstName} {disdata.LastName}</td>
    </tr>
    <tr>
    <td>Email:</td>
    <td colspan="2">{disdata.email}</td>
    </tr>
    <tr>
    <td>Address:</td>
    <td colspan="2">{disdata.Address}</td>
    </tr>
    <tr>
    <td>City:</td>
    <td colspan="2">{disdata.City} , {disdata.State}</td>
    </tr>
    <tr>
    <td>Apartment:</td>
    <td colspan="2">{disdata.Apartment}</td>
    </tr>
    <tr>
    <td>Phone:</td>
    <td colspan="2">{disdata.Phone}</td>
    </tr>
  </tbody>
</table>
                      
            </div>
          </div>
    </>
  )
}