import { createSlice } from "@reduxjs/toolkit";
const axios = require("axios");


export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isloggedIn: false
  },
  reducers: {
    setauth: (state, action) => {

      if(action.payload == false){
        var LocAuthState={
          isLoggedIn:false,
          loggedUserData:{}
        }
        localStorage.setItem('authState',JSON.stringify(LocAuthState))
      }
      state.isloggedIn = action.payload;
    }
    // getauth: (state, action) => {
    //   return state.isloggedIn;
    // }
  }
});

// export const getTodoAsync = (data) => async (dispatch) => {
//   try {
//     const response = await axios.get(`${API_URL}/${data}`);
//     dispatch(getTodo(response.data));
//   } catch (err) {
//     throw new Error(err);
//   }
// };

// export const addTodoAsync = (data) => async (dispatch) => {
//   try {
//     // console.log(data);
//     const response = await axios.post(API_URL, data);
//     // console.log(response);
//     dispatch(addTodo(response.data));
//   } catch (err) {
//     throw new Error(err);
//   }
// };

export const { setauth, getauth } = authSlice.actions;
export const showAuth = (state) => state.auth.isloggedIn;
export default authSlice.reducer;
