import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    useNavigate,
    Navigate
  } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function AddProduct() {
    
    const navigate = useNavigate();
       
    const [producttitle, setProductTitle] = useState('');
    const [productDesc, setProductDesc] = useState('');
    // const [coverImgUrl, setcoverImgUrl] = useState('');
    const [price, setPrice] = useState('');
    const [file, setFile] = useState(null);
    const [imageobj, setImageobj] = useState({});

    const toastObj={
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        }
    
    


    const UPLOAD_ENDPOINT = `${process.env.REACT_APP_API_UPLOAD}`;

    const handlePhotoSubmit = async (e) => {
        e.preventDefault();
        //if await is removed, console log will be called before the uploadFile() is executed completely.
        //since the await is added, this will pause here then console log will be called
        let res = await uploadFile(file);
        console.log(res.data);
        setImageobj(res.data);
    }

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append('avatar', file)

        return await axios.post( `${process.env.REACT_APP_API_UPLOAD}`, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    }

    const handleOnChange = (e) => {
        console.log(e.target.files[0]);
        setFile(e.target.files[0]);
    }

    function addProduct(){

        var productObj = {
            productId:uuidv4(),
            productTitle: producttitle,
            productDescription: productDesc,
            productPrice: price,
            coverImageUrl: imageobj.url,
            Visible: "true",
            inStock: "true",
            
        }
        console.log(productObj)
        axios.post( `${process.env.REACT_APP_API_ADD_PRODUCT}`, productObj).then((result) => {
    
            const resCode = result.data.code
     
            if(resCode == 200){
           
                toast.success(result.data.message, toastObj);

                setTimeout(() => {
                navigate("/dashboard/allproducts");
                    
                }, 4000);
                // console.log(result)
            }else{
 
             alert('Invalid')
            }
            
         })
     

    }

    return (
        <>

            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        Add Product
                    </div>
                </div>
                <div className="card-body">

                    <div className="card">
                        <div className="card-body">

                            <div className="mb-3 row">
                                <label htmlFor="productTitle" className="col-sm-3 col-form-label">Product Title</label>
                                <div className="col-sm-9">
                                    <input type="text" name="productTitle" className="form-control" onChange={(e) => setProductTitle(e.target.value)} value={producttitle || ''} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="productDesc" className="col-sm-3 col-form-label">Product Description</label>
                                <div className="col-sm-9">
                                    <textarea type="text" rows="3" name="productDesc" onChange={(e) => setProductDesc(e.target.value)} value={productDesc || ''} className="form-control" />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="price" className="col-sm-3 col-form-label">Price</label>
                                <div className="col-sm-9">
                                    <input type="text" name="price" onChange={(e) => setPrice(e.target.value)} value={price || ''} className="form-control" />
                                </div>
                            </div>

                          
                        </div>
                    </div>

                    <div className="card mt-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-7 col-sm-7">
                                    <img className="img-fluid" src={imageobj.url} alt="" />
                                </div>
                                <div className="col-lg-5 col-sm-5">
                                    <form onSubmit={handlePhotoSubmit}>

                                        <input type="file" onChange={handleOnChange} />
                                        <button className='btn btn-info mt-2' type="submit">Upload Cover Photo</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="card-footer">
                    <button className='btn btn-success' onClick={()=>addProduct()}>Add New Product</button>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default AddProduct