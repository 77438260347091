import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Outlet, Link } from "react-router-dom";
import NavBar from '../components/NavBar'
function DashBoard() {
  return (
    <>
     <NavBar/> 
    <Outlet/>
    </>
   
  )
}

export default DashBoard