import React, { useState, useEffect } from 'react'
import { Link} from 'react-router-dom'
import axios from 'axios';
import DataTable from 'react-data-table-component';


function Orders() {

  

  const [orders, setOrders] = useState([]);
  const [isLoading, setisLoading] = useState(true)
  const myNewTheme= {
    rows: {
      fontSize: '40px'
    }
  }
  

  const columns = [
    {
      name: '#',
      cell: (row, index) => index + 1,
      grow: 0.1,
    },
    {
        name: 'Order Id',
        selector: row => row.orderId,
        grow: 2,
        
    },
    {
      name: 'Order By',
      selector: row => row.userDetails,
      cell: (row, index) => <Temp obj={row.userDetails} />,

      // cell: (row, index) =>  JSON.parse(row.userDetails).firstName + " "+JSON.parse(row.userDetails).LastName,
      sortable: true,
    },
    {
      name: 'Order Date',
      selector: row => row.DOO  ,
      sortable: true,
      grow: 0.5,
    },
    {
      name: 'Action',
      selector: row => row.year,
      cell: (row, index) =>  <Link to={`orderDetails/${row.orderId}`}>View Order</Link>  ,
    },
  ];

  const fetchProducts = async () => {

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ORDERS}`);
      setOrders(response.data.data)
      setisLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchProducts()

  }, []);

  
  // console.log(orders)




  return (

    <>
      <div className="container py-2">
        <div className="card">
          <div className="card-header bg-w">
            <div className="card-title">
              <h3>Orders </h3>
            </div>
          </div>

          <div className="card-body">

          <DataTable columns={columns} data={orders} pagination customTheme={myNewTheme}/>
            <div className="table-responsive">



              {/* <table className='table'>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Order Id</th>
                    <th>Order By</th>
                    <th>Actions</th>

                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => {

                    return <MiniCard key={index} data={order} i={index} />
                  })}

                </tbody>
              </table> */}

            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Orders


function MiniCard({ data, i }) {

  var userDetails = JSON.parse(data.userDetails)
  var CartItems = JSON.parse(data.cartItems)



  return (
    <>
      <tr key={data.orderId}>
        <td>{i + 1}</td>
        <td>{data.orderId}</td>
        <td><p>{userDetails.firstName} <span>{userDetails.LastName}</span></p> </td>
        <td> <Link to={`orderDetails/${data.orderId}`}><button className='btn btn-primary'>View Order</button></Link> </td>
      </tr>
    </>
  )

}
function Temp({obj}) {

  // var userDetails = JSON.parse(obj)
  var obj2 =obj
var yourString = obj2;
var result = JSON.parse(yourString.substring(0, yourString.length));


  return (
    <>
     <p>{result.firstName} {result.LastName} </p>
    </>
  )

}