import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate
} from "react-router-dom";

import Login from "../components/Login";

function Home() {
  const navigate = useNavigate();
  const logout = (e) => {
    localStorage.setItem('loggedInStatus',false);
    navigate("/");
  };
  return (
    <>
       
    <div className="container-fluid">
    
    </div>
   
        <div className="container mt-3">
        <Login/>
        </div>
      
    

</>
   
  )
}

export default Home