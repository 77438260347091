import React,{useState,useEffect} from 'react'
import axios from 'axios';
function AllProducts() {



  const [products, setProducts] = useState([]);
  const [isLoading, setisLoading] = useState(true)


  const fetchProducts = async () => {

      try {
          const response = await axios.get(`${process.env.REACT_APP_API_GET_ALL_PRODUCTS}`);
          setProducts(response.data.data)
          setisLoading(false)
      } catch (error) {
          console.log(error)
      }
  }
  useEffect(() => {
      fetchProducts()

  }, []);


 

  return (
    
    <>
    <div className="container py-2">
    <div className="card">
      <div className="card-header bg-w">
        <div className="card-title">
         <h3>All Products </h3> 
        </div>
      </div>


      <div className="card-body">

     {isLoading ? (".....Loading"): (

      <>
       {products.map((product, index) => {

         return <MiniCard key={index} data={product} />
})}
      </>
     )}

     
          
      </div>
    </div>
    </div>
    
    </>
  )
}

export default AllProducts


function MiniCard({data}){





  return(

    <div className="card mb-3 ">
    <div className="card-body d-flex align-items-center justify-content-between">

      <h4 className='w-40'>{data.productTitle}</h4>
      <button className='btn btn-secondary'>Edit</button>
      
      {data.Visible == "true" ? (<button className='btn btn-primary'>hide</button>) :(<button className='btn btn-primary'>Show</button>)}

      {data.inStock == "true" ? (<button className='btn btn-danger'>Out of Stock</button>) :(<button className='btn btn-success'>In Stock</button>)}
      

    </div>
  </div>
  )

}