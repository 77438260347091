import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { showAuth, setauth } from "../redux/authSlice";
import { Outlet, Link, NavLink } from "react-router-dom";
function NavBar() {
  const dispatch = useDispatch();
  return (
    <header>

<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <div className="container-fluid">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <a className="navbar-brand" href="#">i TEA PARTY</a>
    <div className="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo03">
      <ul className="navbar-nav ml-auto text-left">
        <li className="nav-item px-2 py-1">
          <NavLink className="nav-link" aria-current="page" to="orders">Orders</NavLink>
        </li>
        
        <li className="nav-item dropdown px-2 py-1">
          <a className="nav-link dropdown-toggle" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
           Products
          </a>
          <ul className="dropdown-menu ml-10" aria-labelledby="navbarScrollingDropdown">
            <li><NavLink className="dropdown-item" to="allproducts">All Products</NavLink></li>
            <li><NavLink className="dropdown-item" to="addproduct">Add Product</NavLink></li>
            
          </ul>
        </li>
        <li className="nav-item px-2 py-1">
          {/* <NavLink className="nav-link " aria-current="page" to="searchHistory">Search History</NavLink> */}
        </li>
        <li className="nav-item px-2 py-1">
          <button className='btn btn-outline-danger' onClick={() => dispatch(setauth(false))}> Log Out</button>
        </li>
      </ul>
      
    </div>
  </div>
</nav>
    </header>
  )
}

export default NavBar