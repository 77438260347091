import { useEffect, useState } from "react";
import {
  BrowserRouter,
  NavLink,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { showAuth,setauth } from "./redux/authSlice";
import Home from './pages/Home'
import DashBoard from './pages/DashBoard'
import AddProduct from './pages/AddProduct'
import AllProducts from './pages/AllProducts'
import Orders from './pages/Orders'
import SearchHist from './pages/SearchHist'


import Error from './pages/Error'
import OrderDetails from "./pages/OrderDetails";


export default function App() {
  
  // let [loggedIn, setLoggedIn] = useState(null);

  const loggedIn = useSelector(showAuth);
  const dispatch = useDispatch();

  useEffect(() => {
 
   var preLogStatus=JSON.parse(localStorage.getItem("authState"))
  

   if(preLogStatus == null || preLogStatus == undefined){
    dispatch(setauth(false))
   }else if(preLogStatus.isLoggedIn){
  dispatch(setauth(true))
   }
  else{
  dispatch(setauth(false))
   }
   
  },)
  


  // console.log(loggedIn);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            loggedIn ? (
              <Navigate to="/dashboard" />
            ) : (
              // <Home login={handleLogin} />

              <Home />
            )
          }
        />
        
        <Route path="*" element={<Error/>} />
        <Route
          path="dashboard"
          element={
            loggedIn ? (
              // <Dashboard logout={handleLogout} />
              <DashBoard />
            ) : (
              <Navigate to="/" state="From Dashboard" />
            )
          }
        >
           <Route
            index 
            element={<Orders/>}
          />
           <Route
            path='orders'
            element={<Orders/>}
         />
             <Route
            path="orders/orderDetails/:id"
            element={<OrderDetails/>}
          />
           <Route
            path="orderDetails/:id"   
            element={<OrderDetails/>}
          />
          
          
            <Route
            path="searchHistory"
            element={<SearchHist/>}
          />
          <Route
            
            path="addproduct"
            element={<AddProduct/>}
          />
          <Route
            path="allproducts"
            element={<AllProducts/>}
          />
          
        </Route>
      </Routes>
    </BrowserRouter>
  );
}


