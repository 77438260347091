import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showAuth, setauth } from "../redux/authSlice";
import axios from "axios";

import loginimg from "./admin.jpg";

export default function Login() {

  const dispatch = useDispatch();

  const [user, setUser] = useState({
    email: '', password: ''
  })

  function handleChange(e) {
    setUser({ ...user, [e.target.name]: e.target.value })

  }
 
  const  login=(event,state)=>{
    event.preventDefault();
    const sendData={
      email:user.email,
      password:user.password,
    
    }

    console.log(sendData)
        // axios.post('http://localhost/API/MyApi/login.php', sendData).then((result) => {
        axios.post(`${process.env.REACT_APP_API_LOGIN}`, sendData).then((result) => {

    
           const resCode = result.data.code
    
           if(resCode == 200){
            dispatch(setauth(true))
            var LocAuthState={
              isLoggedIn:true,
              loggedUserData:JSON.parse(result.data.data)
            }
            localStorage.setItem('authState',JSON.stringify(LocAuthState))
           }else{

            alert('Invalid Login')
           }
           
          // console.log(JSON.parse(result.data.data))
        })
    
      }

  


  return (
    <div className="login">
      <div className="container login_main">
        <div className="main_card">
          <div className="card login_card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-5">
                  <img className="img-fluid" src={loginimg} alt="" />
                </div>

                <div className="col-lg-7 center_v">
                  <div className="login_form">
                    <div className="card">
                      <div className="card-body">
                        <form>
                          <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                              Email address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              onChange={handleChange} value={user.email}
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                              Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              onChange={handleChange} value={user.password}
                            />
                          </div>

                          <button
                            type="button"
                            onClick={login}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
